<template>
  <page-main class="customize-page-main">
    <el-tabs v-model="activeName" class="tabs">
      <el-tab-pane label="视频" name="video">
        <TabVideo v-if="activeName === 'video'" :handle-parent-dialog="handleParentDialog" :options-list="optionsList" />
      </el-tab-pane>
      <el-tab-pane label="文献资料" name="knowledge">
        <TabKnowledge v-if="activeName === 'knowledge'" :handle-parent-dialog="handleParentDialog" :options-list="optionsList" />
      </el-tab-pane>
      <el-tab-pane label="题目" name="exam">
        <TabExam
          v-if="activeName === 'exam'"
          :handle-parent-dialog="handleParentDialog"
          :options-list="optionsList"
        />
      </el-tab-pane>
    </el-tabs>
    <classification-management v-model="visible" :table-data.sync="optionsList" :category-type="activeName" />
    <el-dialog
      title="温馨提示"
      :visible.sync="showInvalidDialog"
      width="30%"
    >
      <p>
        尊敬的客户，您好！<br>我们注意到，您的一部分素材因重复创建而被系统自动转移至回收站并进行了冻结处理。考虑到学术素材共享功能的最新启用，此举是为了更好地维护素材的秩序与效率。<br>
        若您在操作过程中遇到任何不便或疑问，欢迎随时联系我们的运营团队，我们将耐心为您提供支持与帮助。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showInvalidDialog = false">知道了</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="温馨提示"
      :visible.sync="showNotification"
      width="30%"
    >
      <p>
        尊敬的客户，顶级组织{{ notificationObj.orgName }}，将您的<span v-if="notificationObj.videosCount">{{ notificationObj.videosCount }}个视频，</span><span v-if="notificationObj.knowledgeCount">{{ notificationObj.knowledgeCount }}份文件，</span><span v-if="notificationObj.examCount">{{ notificationObj.examCount }}道题目，</span>已做下架处理。如需了解详情，请前往素材回收站查看。如有疑问，可通过线下渠道沟通反馈。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="onToCheck">前往查看</el-button>
        <el-button type="primary" @click="showNotification = false">我知道了</el-button>
      </span>
    </el-dialog>
  </page-main>
</template>

<script>
import TabVideo from './TabVideo.vue'
import TabKnowledge from './TabKnowledge.vue'
import TabExam from './TabExam.vue'
import ClassificationManagement from './components/ClassificationManagement.vue'

export default {
  name: 'List',
  components: {
    TabVideo,
    TabKnowledge,
    TabExam,
    ClassificationManagement
  },
  data() {
    return {
      activeName: 'video',
      visible: false,
      optionsList: [],
      showInvalidDialog: false,
      showNotification: false,
      notificationObj: {}
    }
  },
  mounted() {
    this.activeName = this.$route.query.activeName || 'video'
    this.onGetTopDelCount()
    // 素材管理弹窗
    // 如果已经弹过窗，也就是hasInvalidShowed为true，那么就不再弹窗
    if (window.localStorage.getItem('hasInvalidShowed') !== '1') {
      this.showInvalidDialog = true
      window.localStorage.setItem('hasInvalidShowed', 1)
    }
  },
  methods: {
    handleParentDialog() {
      this.visible = true
    },
    onToCheck() {
      this.showNotification = false
      this.$router.push({
        name: 'RecoveryStation'
      })
    },
    onGetTopDelCount() {
      this.$axios.get(this.$API.getTopDelCount).then(res => {
        console.log(res)
        this.notificationObj = res.data
        this.showNotification = res.data.isShow
      }, err => {
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tabs {
  ::v-deep .el-tabs__header {
    margin-bottom: 0;
    .el-tabs__nav-wrap {
      overflow: inherit;
      &::after {
        width: calc(100% + 2 * #{$g-main-wrap-padding});
        left: -$g-main-wrap-padding;
        height: 1px;
      }
    }
  }
}
</style>
